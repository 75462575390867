import people from '../../assets/people_image.png'
import twitter from '../../assets/twitter.png'
import telegram from '../../assets/telegram.png'
import lick_1 from '../../assets/lick_1.png'
import Navbar from '../../components/Navbar/Navbar'
import styles from './LandingPage.module.css'
import { useEffect, useRef } from 'react'

const LandingPage = () => {
  const mainRef = useRef<HTMLDivElement>(null)
  const mainRefParent = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let requestId: number;

    const update = (e: MouseEvent) => {
      if (mainRef.current) {
        const amountMovedX = e.pageX / 3;
        const amountMovedY = e.pageY / 3;

        // Use requestAnimationFrame to update the background position
        requestId = requestAnimationFrame(() => {
          if (mainRef.current) {
            mainRef.current.style.backgroundPosition = `${amountMovedX}px ${amountMovedY}px`;
          }
        });
      }
    };

    if (mainRefParent.current) {
      mainRefParent.current.addEventListener("mousemove", update);
    }

    return () => {
      // if (mainRefParent.current) {
      //   mainRefParent.current.removeEventListener("mousemove", update);
      // }

      // Cancel any pending animation frame requests when the component unmounts
      if (requestId) {
        cancelAnimationFrame(requestId);
      }
    }
  }, [])

  return (
    <div id="top" className={styles.container}  ref={mainRefParent}>
        {/* Navbar */}
        <Navbar />

        <section style={window.innerWidth > 1536 ? { zoom: `${((window.innerWidth - 1536)*0.06510416666666667) + 100}%` } : {}} className={styles.main_section} ref={mainRef}>
          <div className={styles.text_container}>
            <h1 className={styles.heading}>MemeCoin That’s Lickably Good!</h1>
            {/* <h2 className={styles.sub_heading}>Experience a new level of excitement with Lick Coin. It’s not just another crypto; it’s lickably irresistible!</h2> */}
            <div className={styles.buttons_hldr}>
              <a href="#buy" className={styles.btn_holder}>
                <img src={'https://res.cloudinary.com/seimutants/image/upload/v1724662074/imhwtz50i1qfv4mlo258.svg'} alt="" className='w-[20px] h-[20px]' />  
                <span style={{ marginTop: '2px' }}>Buy Token</span>
              </a>
              <a href="https://x.com/LickOnSolana" target="_blank">
                <button className={styles.btn_holder}>
                  <img src={'https://res.cloudinary.com/seimutants/image/upload/v1724662074/uxpjc2cwdwbkopel2zh4.svg'} alt="" />
                </button>
              </a>
              <a href="http://t.me/Lick_Sol" target="_blank">
                <button style={{ paddingLeft: '15px' }} className={styles.btn_holder}>
                  <img src={'https://res.cloudinary.com/seimutants/image/upload/v1724662074/az8u12ydhvvin7kuxzov.svg'} alt="" />
                </button>
              </a>
            </div>
          </div>
          
          <img style={{ width: '2000px', maxWidth: '10000px', left: '-310px' }} src={'https://res.cloudinary.com/seimutants/image/upload/v1725351965/foqvasuzpulpnahcc8kj.webp'} alt="" className={styles.people_image} />
        </section>
    </div>
  )
}

export default LandingPage